import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import styled from 'styled-components';
import { UPDATE_USER } from '../../../api/GraphQL';
import Column from '../../ui/Column';
import Button from '../../ui/form/Button';
import ButtonGroup from '../../ui/form/ButtonGroup';
import Input from '../../ui/form/Input';
import NumberSpinner from '../../ui/form/NumberSpinner';
import Row from '../../ui/Row';

const Main = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 100%;
  margin-left: -15px;
  padding: 15px;
`;

export default function Overview({ user }) {
  const [newUser, setNewUser] = useState(user);
  const [newPassword, setNewPassword] = useState();
  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER, {
    refetchQueries: ['GetUsers', 'GetUser'],
  });

  return (
    <Main>
      <Column size="12">
        <Row bottom_border>
          <Column size="4">Profile</Column>
          <Column size="4">
            <Row>{user?._id}</Row>
            <Row>
              <Input label="Username" value={user?.username} />
            </Row>
            <Row>
              <Input label="Email" value={user?.email} />
            </Row>
          </Column>
        </Row>
        {/* <Row bottom_border>
          <Column size="4">Account disabled</Column>
          <Column size="4">
            <Row>
              <ButtonGroup
                value={user?.disabled || false}
                items={[
                  { label: 'No', value: false },
                  { label: 'Yes', value: true },
                ]}
                onChange={(value) => {
                  updateUser({
                    variables: {
                      _id: user._id,
                      disabled: value,
                    },
                  });
                }}
              />
            </Row>
          </Column>
        </Row> */}
        <Row bottom_border>
          <Column size="4">Password</Column>
          <Column size="4">
            <Row>
              <Input
                label="New Password"
                value={newPassword}
                type="password"
                onChange={(e) => setNewPassword(e.currentTarget.value)}
              />
              <Button
                label="Update"
                onClick={() => {
                  updateUser({
                    variables: {
                      _id: user._id,
                      password: newPassword,
                    },
                  });
                }}
              />
            </Row>
          </Column>
        </Row>
        <Row bottom_border>
          <Column size="4">License</Column>
          <Column size="4">
            <Row>
              <ButtonGroup
                label="License Type"
                value={user?.license_type}
                items={[
                  { label: 'Disable', value: 'DISABLED' },
                  { label: 'Demo', value: 'DEMO' },
                  { label: 'Free', value: 'FREE' },
                  { label: 'Pro', value: 'PRO' },
                  { label: 'Perpetual', value: 'PERPETUAL' },
                ]}
                onChange={(value) => {}}
              />
            </Row>
          </Column>
        </Row>
        <Row bottom_border>
          <Column size="4">Project Limit</Column>
          <Column size="4">
            <Row>
              <NumberSpinner
                value={user?.project_limit}
                min_value="1"
                onChange={(project_limit) => {
                  setNewUser({ ...user, project_limit });
                }}
              />
              {user?.project_limit !== newUser?.project_limit && (
                <Button
                  label="Save"
                  onClick={() => {
                    updateUser({
                      variables: {
                        _id: user._id,
                        project_limit: newUser.project_limit,
                      },
                    });

                    setNewUser({
                      ...user,
                      project_limit: newUser.project_limit,
                    });
                  }}
                />
              )}
            </Row>
          </Column>
        </Row>
        <Row>
          {user?.features?.map((feature) => {
            return <div>{feature.name}</div>;
          })}
        </Row>
      </Column>
    </Main>
  );
}
